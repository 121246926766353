<template>
  <ik-data-table
    :entity-name="$t('section')"
    :model="model"
    icon="mdi-desktop-tower-monitor"
  />
</template>

<script>

  import Section from '../../../model/Section'
  import Servant from '../../../model/tableAndPerson/Servant'
  import IKUtils from 'innerken-js-utils'
  import { IkDataTable } from 'metaflow-js'
  import hillo from 'hillo'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: Section,
        displayData: [],
        loading: false,
        fixedHeader: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Password',
            value: 'password',
          },
        ],
        sectionHeaders: [],
      })
    },
    computed: {
      headers () {
        return this.fixedHeader.concat(this.sectionHeaders)
      },
      tableData () {
        const tableData = []
        this.displayData.forEach(d => {
          this.sectionHeaders.forEach(s => {
            s.copyServantsIds = IKUtils.deepCopy(s.servantIds)
            if (d.id) {
              if ((s.servantIds !== null) && (s.servantIds.length > 0)) {
                d[s.name] = (s.servantIds.indexOf(d.id) > -1)
              } else {
                d[s.name] = false
              }
            }
          })
          tableData.push(d)
        })
        console.log(tableData, 'item')
        return tableData
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      async updateSection (item, sItem) {
        this.loading = false
        item[sItem.name] = !item[sItem.name]
        if (typeof (sItem.copyServantsIds) === 'string') {
          sItem.copyServantsIds = JSON.parse(sItem.copyServantsIds)
        }
        if (item[sItem.name]) {
          sItem.copyServantsIds.push(item.id)
        } else {
          sItem.copyServantsIds.splice(sItem.copyServantsIds.indexOf(item.id), 1)
        }
        sItem.servantIds = JSON.stringify(sItem.copyServantsIds)
        await hillo.post('Section.php?op=update', sItem)
      },
      async loadData () {
        this.sectionHeaders = (await Section.getList()).map(a => {
          a.text = a.name
          a.value = a.name
          return a
        })
        this.displayData = (await Servant.getList())
      },
    },
  }
</script>
